#footer {
	background-image: linear-gradient(to right, #0066CC, #FFFFFF);
	color: #000000;
	clear: both;
	height: 25px;
	font-size: 80%;
	text-align: center;
}
#footer p {
	font-style: italic;
	padding: 5px 0px 5px 0px;
	margin: 0px 0px 0px 0px;
}