#contactDetails dt {
	margin: 10px 0px 5px 10px;
}
#contactDetails dt:after {
	content: " :";
}
#contactDetails dd {
	text-overflow: ellipsis;
	width: 75%;
	overflow: hidden;
}
#contactDetails .address .addressLine, #contactDetails .address .postcode {
	float: left;
	width: 100%;
}
#contactDetails .address .addressLine:after {
	content: "";
}
#contactDetails .areaCode:after {
	content: " ";
}
#contactDetails .localNumber {
	
}