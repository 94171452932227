#sidebar {
	display: block;
	float: left;
	padding: 0px;
	width: 25%;
	height: 100%;
}
@media only screen and (max-width: 400px) {
	#sidebar { 
		margin: 0px 0px 0px 0px;
		width: 100%; 
		background-image: linear-gradient(to right, #4E9FEE, #FFFFFF);
	}
}