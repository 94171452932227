#accreditations {
	float: right;
	min-width: 175px;
	width: 45%;
	text-align: right;
}
#accreditations ul {
	margin: 0px 10px 0px 0px;
	padding: 0px 0px 0px 0px;
}
#accreditations ul li {
	list-style-type: none;
}
#accreditations .caption {
	font-size: 75%;
	margin: 5px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
}
@media only screen and (max-width: 400px) {
	#accreditations { 
		width: 100%; 
	}
}