.linksList:after {
	content: "";
	clear: both;
	display: block;
}
.linksList li {
	list-style: none;
	margin: 5px;
	padding: 10px;
	border: 5px outset;
	border-color: lightgrey silver silver lightgrey;
    min-height: 250px;
    min-width: 200px;
    float: left;
    position: relative;
}
@media screen and (min-width: 401px) and (max-width : 1200px) {
	.linksList li {
	    width: 40%;
	}
	.linksList li:nth-child(odd) {
		clear: left;
	}
}
@media screen and (min-width: 1201px) {
	.linksList li {
	    width: 27%;
	}
	.linksList li:nth-child(4n+0) {
		clear: left;
	}
}
.linksList li:hover {
	box-shadow: 5px 5px 5px #888888;
}
.linksList li img {
	display: inline;
	margin: 5px;
    max-height: 100px;
    max-width: 100px;
}
.linksList li a {
	display: block;
	font-size: 75%;	
	position: absolute;
	bottom: 5px;
	left: 0px; 
	right: 0px;
	text-align: center;
}
.linksList li:hover a {
	font-size: 100%;
	text-decoration: underline;
}
.linksList li .description {
	margin: 0px 5px 25px 5px !important;
}
@media screen and (max-width: 400px) {
	.linksList {
		padding: 0px;
	}
	.linksList li {		
		min-width: 85%;
    	width: 85%;
    	text-align: center;
	}
	.linksList li img {
		height: auto;
		width: auto;
		max-width: 100px;
		max-height: 100px;		
	}
}