#page {
	clear: both;
	background-image: linear-gradient(to right, #4E9FEE, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF);
}
#page:after {
	content: "";
	clear: both;
	display: block;
}
#pageContent {
	display: block;
	float: left;
	margin: 0px 10px 0px 10px;
	width: 70%;
}
@media only screen and (max-width: 400px) {
	#pageContent { 
		margin: 0px 0px 0px 0px;
		width: 100%; 
	}
	#pageContent h2, #pageContent p {
		margin: 0px 10px 10px 10px;
	}
	#page {
		background-image: none;
	}
}