#menu ul {
	margin: 0px;
	padding: 0px;
}
#menu li {
	list-style-type: none;
	margin: 5px 0px 5px 0px;
	width: 100%;
}
#menu li a {
	background-image: linear-gradient(to right, #0066CC, #FFFFFF);
	color: #FFFFFF;
	display: block;
	text-decoration: none;
	font-weight: bold;
	height: 100%;
	padding: 5px 0px 5px 10px;
}
#menu li.current a {
	background-image: linear-gradient(to right, #0066CC, #4E9FEE);	
}