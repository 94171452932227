#banner {
	background-image: linear-gradient(to right, #0066CC, #FFFFFF);
	padding: 0px 0px 5px 0px;
	width: 100%;
}
#banner:after {
	content: "";
	clear: both;
	display: block;
}
#banner h1 {
	color: #FFFFFF;
	float: left;
	font-size: 225%;
	padding-left: 30px;
	width: 45%;
}
#banner #sub-title {
	clear: left;
	display: block;
	font-size: 55%;
}
@media only screen and (max-width: 400px) {
	#banner h1 { 
		width: auto; 
	}
}